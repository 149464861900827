<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card :title="$t(resources.Coupons.i18n) || resources.Coupons.name">
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                  couponsList.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : couponsList.length
                  }}
                  of {{ couponsList.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search..."
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button>
            <vs-button class="mb-4 md:mb-0 ml-1" @click="popupGenerate = true">
              {{
              $t(resources.Generate.i18n) || resources.Generate.name
              }}
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="couponsList"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
      <vs-popup
        :title="$t(resources.Create.i18n) || resources.Create.name"
        :active.sync="popupGenerate"
      >
        <div class="vx-col w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              v-model="code"
              class="w-full"
              icon-pack="feather"
              icon="icon-tag"
              icon-no-border
              disabled
              :label="$t(resources.Code.i18n) || resources.Code.name"
            />
          </div>
        </div>
        <div class="vx-col w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              v-model="name"
              class="w-full"
              icon-pack="feather"
              icon="icon-edit"
              icon-no-border
              :label="$t(resources.Name.i18n) || resources.Name.name"
            />
          </div>
        </div>

        <div class="vx-col w-full">
          <vs-input
            v-model="subject"
            class="w-full"
            icon-pack="feather"
            icon="icon-edit"
            icon-no-border
            :label="$t(resources.Subject.i18n) || resources.Subject.name"
          />
        </div>

        <div class="vx-col w-full mb-2">
          <vs-input
            v-model="email"
            type="email"
            class="w-full"
            icon-pack="feather"
            icon="icon-mail"
            icon-no-border
            :label="$t(resources.Email.i18n) || resources.Email.name"
          />
        </div>

        <div class="vx-col w-full">
          <vs-textarea
            v-model="emailText"
            class="w-full"
            icon-no-border
            :label="$t(resources.EmailText.i18n) || resources.EmailText.name"
          />
        </div>
        <vs-button class="mb-4 md:mb-0 ml-1" @click="createGuide(true)">
          {{
          $t(resources.Send.i18n) || resources.Send.name
          }}
        </vs-button>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      resources: resources,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      couponsList: [],
      popupGenerate: false,
      code: "",
      name: "",
      email: "",
      subject: "",
      emailText: "",
      contactId: JSON.parse(localStorage.getItem("userInfo")).id,
      defaultColDef: {
        sortable: true,
        editable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Code"),
          field: "code",
          width: 200,
          filter: true,
          pinned: "left"
        },
        {
          headerName: this.$i18n.t("Used"),
          field: "used",
          filter: true,

          cellRenderer: function(event) {
            let object = "";
            switch (event.node.data.Used) {
              case true:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
              case false:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              default:
                break;
            }
            return object;
          }
        },
        {
          headerName: this.$i18n.t("Email"),
          field: "email",
          filter: true
        },
        {
          headerName: this.$i18n.t("Name"),
          field: "name",
          filter: true
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true
        },
        {
          headerName: this.$i18n.t("Active"),
          field: "active",
          filter: true
        },
        {
          headerName: this.$i18n.t("UseDate"),
          field: "useDate",
          filter: true
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("questionDescription", null);
      } else
        this.gridOptions.columnApi.setColumnPinned(
          "questionDescription",
          "left"
        );
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  created: function() {
    this.code = this.$uuid
      .v1()
      .replace(/-/g, "")
      .substring(0, 20)
      .toUpperCase();

    this.getCoupons();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async getCoupons() {
      await axios({
        method: "GET",
        url: `${this.url.apiMaster}Coupon/getByContactId/${this.$i18n.locale}/${this.contactId}`,
        headers: {
          "content-type": "application/json"
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.couponsList = [];
          } else {
            this.couponsList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>
